import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UtilServiceService {

  public ENDPOINT = environment.urlEndpointApi;

  constructor(
    private http: HttpClient,
    private toastrService: ToastrService

  ) {}

  public loginAutogestion(contrato: string, documento: any) {
    let data = {
      contrato: contrato,
      documento: documento
    }
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.post(this.ENDPOINT + 'loginAutogestion', data, { headers });
  }

  public confirmarNuevo(datos: any){
    let data = {
      datos: datos
    }
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.post(this.ENDPOINT + 'confirmarNuevo', data, { headers });
  }

  public pagarCuota(datos: any, total: any, idCuota: any, metodoNombre: string) {
    let data = {
      datostarjeta: datos,
      totalOperacion: total,
      idOperacion: idCuota,
      metodoNombre: metodoNombre
    }
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.post(this.ENDPOINT + 'generatePaymentMacro', data, { headers });
  }
}
