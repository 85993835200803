import { Component, OnInit } from '@angular/core';
import { UtilServiceService } from '../service/util-service.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  contrato: string;
  metodoNombre: string;
  documento: number;
  dataLoading: boolean;
  nuevoLoading: boolean;
  pagandoLoading: boolean;
  loginStatus: boolean = true
  cuotasStatus: boolean
  pagarStatus: boolean
  pagoError: boolean
  nuevo: boolean
  errorNuevo: boolean
  nuevoCompletar: boolean
  nuevoMensaje: boolean
  pagoOk: boolean
  construccion: boolean = false
  refinanciar: boolean = false;
  paso1: boolean = true
  paso2: boolean
  paso3: boolean
  mensajeError: string
  cuotas: any[] = []
  metodos: any[] = []
  anos: any[] = []
  meses: any[] = []
  localidades: any[] = []
  paises: any[] = []
  tipos: any[] = []
  sexos: any[] = []
  moneda: any
  alimenticios: any[] = []
  planes: any[] = []
  primeras: any[] = []
  parentezcos: any[] = []
  pasajero: any
  elContrato: any
  laCuota: any
  loginForm: FormGroup;
  paxForm: FormGroup;
  hoy: any;
  libre: boolean = false;
  mensajeErrorNuevo: string = ""

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private utilService: UtilServiceService,
    private toastService: ToastrService
  ) {
    this.createLoginForm();
    this.createPaxForm();

  }

  ngOnInit(): void {
    this.crearAnosyMeses();
  }

  createLoginForm(): void {
    this.loginForm = this.formBuilder.group({
      cardNombre: ['', [Validators.required, Validators.minLength(3)]],
      cardMetodo: ['', [Validators.required]],
      cardNumero: ['', [Validators.required, Validators.minLength(16),Validators.maxLength(16), Validators.pattern(/^([0-9])*$/)]],
      cardAno: ['', [Validators.required]],
      cardMes: ['', [Validators.required]],
      cardCodigo: ['', [Validators.required, Validators.minLength(3),Validators.maxLength(3), Validators.pattern(/^([0-9])*$/)]],
      cardNacimiento: ['', [Validators.required]],
      cardPuerta: ['', [Validators.required]],
      cardDNI: ['', [Validators.required, Validators.minLength(7),Validators.maxLength(8), Validators.pattern(/^([0-9])*$/)]],
      cardEmail: ['', [Validators.required, Validators.pattern(/^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/)]]
    })

    this.cardMetodo.valueChanges.subscribe(value => {
      this.metodoNombre = this.metodos.find(metodo => metodo.MedioPagoId == value);
    })
  }

  btnPaso1(){
    this.paso1 = true
    this.paso2 = false
    this.paso3 = false
  }

  btnPaso2(){
    this.paso2 = true
    this.paso1 = false
    this.paso3 = false
  }

  btnPaso3(){
    this.paso3 = true
    this.paso2 = false
    this.paso1 = false
  }

  isInvalid(control: string): boolean {
    return this.loginForm.get(control).invalid && this.loginForm.get(control).touched;
  }

  get cardNombre(): AbstractControl {
    return this.loginForm.get('cardNombre')
  }

  get cardMetodo(): AbstractControl {
    return this.loginForm.get('cardMetodo')
  }

  get cardNumero(): AbstractControl {
    return this.loginForm.get('cardNumero')
  }

  get cardAno(): AbstractControl {
    return this.loginForm.get('cardAno')
  }

  get cardMes(): AbstractControl {
    return this.loginForm.get('cardMes')
  }

  get cardCodigo(): AbstractControl {
    return this.loginForm.get('cardCodigo')
  }

  get cardNacimiento(): AbstractControl {
    return this.loginForm.get('cardNacimiento')
  }

  get cardPuerta(): AbstractControl {
    return this.loginForm.get('cardPuerta')
  }

  get cardDNI(): AbstractControl {
    return this.loginForm.get('cardDNI')
  }

  get cardEmail(): AbstractControl {
    return this.loginForm.get('cardEmail')
  }

  createPaxForm(): void {
    this.paxForm = this.formBuilder.group({
      paxDni: [this.documento, [Validators.required, Validators.minLength(8), Validators.maxLength(8)]],
      paxContrato: [this.contrato, [Validators.required]],
      paxNombre: ['', [Validators.required, Validators.minLength(3)]],
      paxDomicilio: ['', [Validators.required]],
      paxTelefono: ['', [Validators.required, Validators.pattern(/^([0-9])*$/)]],
      paxLocalidad: ['', [Validators.required]],
      paxPais: [2, [Validators.required]],
      paxEmail: ['', [Validators.required, Validators.pattern(/^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/)]],
      paxNacimiento: ['', [Validators.required]],
      paxDto: [1, [Validators.required]],
      paxAlimenticio: ['', [Validators.required]],
      paxSexo: ['', [Validators.required]],
      paxFacebook: ['', [Validators.nullValidator]],
      paxInstagram: ['', [Validators.nullValidator]],
      resDni: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(8)]],
      resNombre: ['', [Validators.required, Validators.minLength(3)]],
      resDomicilio: ['', [Validators.required]],
      resTelefono: ['', [Validators.required, Validators.pattern(/^([0-9])*$/)]],
      resLocalidad: ['', [Validators.required]],
      resEmail: ['', [Validators.required, Validators.pattern(/^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/)]],
      resNacimiento: ['', [Validators.required]],
      resDto: [1, [Validators.required]],
      resParentezco: ['', [Validators.required]],
      resSexo: ['', [Validators.required]],
      plaPlan: ['', [Validators.required]],
      plaPrimera: ['', [Validators.required]],
      plaObservacion: [''],
    })
  }

  isInvalidPax(control: string): boolean {
    return this.paxForm.get(control).invalid && this.paxForm.get(control).touched;
  }
  get paxDni(): AbstractControl {
    return this.paxForm.get('paxDni')
  }
  get paxContrato(): AbstractControl {
    return this.paxForm.get('paxContrato')
  }
  get paxNombre(): AbstractControl {
    return this.paxForm.get('paxNombre')
  }
  get paxDomicilio(): AbstractControl {
    return this.paxForm.get('paxDomicilio')
  }
  get paxTelefono(): AbstractControl {
    return this.paxForm.get('paxTelefono')
  }
  get paxEmail(): AbstractControl {
    return this.paxForm.get('paxEmail')
  }
  get paxInstagram(): AbstractControl {
    return this.paxForm.get('paxInstagram')
  }
  get paxFacebook(): AbstractControl {
    return this.paxForm.get('paxFacebook')
  }
  get paxSexo(): AbstractControl {
    return this.paxForm.get('paxSexo')
  }
  get paxAlimenticio(): AbstractControl {
    return this.paxForm.get('paxAlimenticio')
  }
  get paxDto(): AbstractControl {
    return this.paxForm.get('paxDto')
  }
  get paxNacimiento(): AbstractControl {
    return this.paxForm.get('paxNacimiento')
  }
  get paxLocalidad(): AbstractControl {
    return this.paxForm.get('paxLocalidad')
  }
  get paxPais(): AbstractControl {
    return this.paxForm.get('paxPais')
  }

  get resParentezco(): AbstractControl {
    return this.paxForm.get('resParentezco')
  }
  get resNombre(): AbstractControl {
    return this.paxForm.get('resNombre')
  }
  get resDomicilio(): AbstractControl {
    return this.paxForm.get('resDomicilio')
  }
  get resTelefono(): AbstractControl {
    return this.paxForm.get('resTelefono')
  }
  get resLocalidad(): AbstractControl {
    return this.paxForm.get('resLocalidad')
  }
  get resEmail(): AbstractControl {
    return this.paxForm.get('resEmail')
  }
  get resNacimiento(): AbstractControl {
    return this.paxForm.get('resNacimiento')
  }
  get resDto(): AbstractControl {
    return this.paxForm.get('resDto')
  }
  get resDni(): AbstractControl {
    return this.paxForm.get('resDni')
  }
  get resSexo(): AbstractControl {
    return this.paxForm.get('resSexo')
  }
  get plaPlan(): AbstractControl {
    return this.paxForm.get('plaPlan')
  }
  get plaPrimera(): AbstractControl {
    return this.paxForm.get('plaPrimera')
  }
  get plaObservacion(): AbstractControl {
    return this.paxForm.get('plaObservacion')
  }

  crearAnosyMeses() {
    for (let index = 0; index < 10; index++) {
      let fecha = new Date();
      let ano = fecha.getFullYear() - 2000;
      this.anos.push(index + ano)

    }
    for (let index = 1; index < 13; index++) {
      this.meses.push(index)
    }
  }

  pagarCuota(cuota: any) {

    this.loginForm.reset()
    this.cuotasStatus = false
    this.pagarStatus = true
    this.laCuota = cuota
  }

  btnCancelar(){
    this.cuotasStatus = true
    this.pagarStatus = false
    this.pagoOk = false
    this.pagoError = false
    this.laCuota = null
    this.libre = false
    this.nuevo = false
    this.errorNuevo = false
    this.nuevoCompletar = false
    this.loginStatus = true
    this.loginForm.reset()

  }

  btnCancelarCuotas(){
    this.cuotasStatus = false
    this.pagarStatus = false
    this.pagoOk = false
    this.pagoError = false
    this.laCuota = null
    this.libre = false
    this.nuevo = false
    this.errorNuevo = false
    this.nuevoCompletar = false
    this.nuevoMensaje = false
    this.loginStatus = true

  }

  btnNuevo(){
    if (!this.paxForm.valid) {
      this.nuevoCompletar = true
      this.paxForm.markAllAsTouched();
      return;
    }
    this.nuevoLoading = true
    this.utilService.confirmarNuevo(this.paxForm.value).subscribe((resp) => {
      this.nuevoLoading = false
      this.nuevo = false
      this.nuevoMensaje = true

    }, (err) => {
      console.log(err)
      this.mensajeErrorNuevo = err['error']['status']
      this.errorNuevo = true
      this.nuevoLoading = false
    }
    )
  }

  btnPagar() {
    if (!this.loginForm.valid) {
      this.loginForm.markAllAsTouched();
      return;
    }

    this.pagandoLoading = true
    this.dataLoading = true
    this.utilService.pagarCuota(this.loginForm.value, this.laCuota['Importe_2'], this.laCuota['idcuotas'], this.metodoNombre['Descripcion']).subscribe((resp) => {
      this.dataLoading = false
      this.pagandoLoading = false
      if(resp['status']){
        this.mensajeError = resp['message']
        this.cuotasStatus = false
        this.pagarStatus = false
        this.pagoError = false
        this.pagoOk = true
      }
      else{
        this.pagoError = true
        this.pagoOk = false
        this.mensajeError = resp['message']
      }
    }, (err) => {
      this.pagoError = true
      this.pagoOk = false
      this.dataLoading = false
      this.pagandoLoading = false
      this.mensajeError = err['error']['message']
    }
    )


  }

  verFactura(cuota: any) {
    window.open("https://admin.viafinturismo.tur.ar/comprobanteDocumento/"+cuota['idcuotas']+"_")
  }

  libreDeuda() {
    window.open("https://admin.viafinturismo.tur.ar/libredeuda/"+this.pasajero['idpasajero'])
  }

  loginAutogestion() {
    this.dataLoading = true
    this.utilService.loginAutogestion(this.contrato, this.documento).subscribe((resp) => {
      this.dataLoading = false

      if(resp['status']=='OK'){
        this.cuotasStatus = true
        this.pagarStatus = false
        this.laCuota = null
        this.pagoError = false
        this.pagoOk = false
        this.nuevo = false
        this.loginStatus = false
        this.hoy = resp['hoy']
        this.libre = resp['libre']
        this.cuotas = resp['cuotas']
        this.metodos = resp['metodos']
        this.pasajero = resp['pasajero']
        this.elContrato = resp['contrato']
        this.moneda = resp['moneda']
        this.refinanciar = resp['refinanciar']
      }
      else{
        this.loginStatus = false
        this.cuotasStatus = false
        this.localidades = resp['localidades']
        this.sexos = resp['sexos']
        this.paises = resp['paises']
        this.alimenticios = resp['alimenticios']
        this.planes = resp['planes']
        this.primeras = resp['primeras']
        this.tipos = resp['tipos']
        this.parentezcos = resp['parentezcos']
        this.paxForm.get('paxDni').setValue(this.documento)
        this.paxForm.get('paxContrato').setValue(this.contrato)
        this.nuevo = true
      }

    }, (err) => {
      this.dataLoading = false
      this.nuevo = false
    }
    )
  }
}
