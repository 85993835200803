<div id="login" class="fondo">
  <nav class="navbar navbar-expand-lg navbar-light colorFondoNavBar">
    <a class="tamañoImagenLogo" href="https://viafinturismo.tur.ar"><img class="navbar-brand  img-fluid"
        src="assets/viafinlogo.png" alt="Italian Trulli"></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

  </nav>
  <br>
  <div class="" *ngIf="construccion">
    <div class="text-center text-white">
      <h1>Estamos trabajando para mejorar nuestro sitio.</h1>
      <h1>Pronto estaremos nuevamente en línea...</h1>
      <h1>Gracias.</h1>
    </div>
  </div>
  <div class="row" *ngIf="!construccion">
    <div class="col-12" *ngIf="nuevoMensaje">

        <div class="text-center" *ngIf="!nuevoLoading">
          <button type="button" class="btn btn-success m-2" (click)="btnCancelarCuotas()">Continuar</button>
          <div class="alert alert-success alert-dismissible fade show" role="alert">
            <strong>Genial!:</strong>
            <br>
            <h5 class="">Hemos recibido tus datos... Verificaremos los mismos y aprobaremos tu inscripcion si todo está correcto.  Gracias....</h5>
          </div>
        </div>

    </div>
    <div class="col" *ngIf="!nuevo">
      <div class="text-center">
        <div class="spinner-border" role="status" *ngIf="dataLoading">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div class="container" *ngIf="loginStatus">
        <div id="login-row" class="row justify-content-center align-items-center">
          <div id="loginAutogestion" class="col-md-9">
            <div class="row" id="login-box">

              <div class="col-md-5 pb-2 bg-white">

                <h3 class="mt-4 pb-3 text-center">Autogestion</h3>
                <form class="mt-4 pb-2" method="POST">
                  <div class="form-group">
                    <label for="username" class="text-dark">N° de Contrato:</label><br>
                    <input type="text" name="username" class="form-control" [(ngModel)]="contrato">
                  </div>
                  <div class="form-group">
                    <label for="password" class="text-dark">N° de Documento:</label><br>
                    <input type="password" name="password" class="form-control" [(ngModel)]="documento"
                      (keyup.enter)="loginAutogestion()">
                  </div>
                  <div class="form-group" id="divContenedorIniciarSesionRespuesta"></div>
                </form>
                <button id="btnIngresarAutogestion" class="btn btn-success mt-4 col-md-12"
                  (click)="loginAutogestion()">Ingresar</button>
                <a href="https://viafinturismo.tur.ar" class="btn btn-outline-dark mt-2 col-md-12">Volver</a>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- <div class="container" *ngIf="libre">
        <div class="alert alert-success alert-dismissible fade show" role="alert">
          <strong>¡Excelente, has pagado todas las cuotas de tu viaje!:</strong>
          <br>
          <h5 class="card-header">Puedes imprimir tu libre deuda </h5>
          <button type="button" class="btn btn-secondary m-2" (click)="libreDeuda()">Imprimir</button>
        </div>
      </div> -->
      <div class="container" *ngIf="cuotasStatus">
        <div class="card">
          <div class="card-body">
            <h2>Pasajero: {{pasajero['Apellido_y_Nombre']}}</h2>
            <h3>Contrato: {{elContrato['Descripcion']}} - Moneda: {{moneda['Simbolo']}}</h3>
            <h3>Viaje: {{elContrato['viaje']['Descripcion']}}</h3>
            <div class="text-center" *ngIf="refinanciar">
              <button type="button" class="btn btn-success m-2" (click)="btnCancelarCuotas()">Continuar</button>
              <div class="alert alert-warning alert-dismissible fade show" role="alert">
                <strong>Atencion!:</strong>
                <br>
                <h5 class="">Debes comunicarte con casa central de forma directa enviando un mail a: administracion@viafinturismo.tur.ar . Gracias....</h5>
              </div>
            </div>
            <table class="table">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">Detalle</th>
                  <th scope="col">Vencimiento</th>
                  <th  class="text-rigth" *ngIf="elContrato['Codigo_moneda'] > 1" scope="col">Importe u$s</th>
                  <th  class="text-rigth" scope="col">Importe $</th>
                  <th scope="col">Estado</th>
                  <th scope="col">Accion</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let cuota of cuotas">
                  <th scope="row">{{cuota['Detalle']}}</th>
                  <td>{{cuota['Vto_2'] | date: "dd/MM/Y"}}</td>
                  <td class="text-center" *ngIf="elContrato['Codigo_moneda'] > 1">{{cuota['Importe_1']}}</td>
                  <td class="text-center" *ngIf="cuota['Detalle'] != 'Seña pagada' && cuota['Pagado']==0">{{cuota['Importe_2']}}</td>
                  <td class="text-center" *ngIf="cuota['Detalle'] != 'Seña pagada' && cuota['Pagado']>0">{{cuota['Pagado']}}</td>

                  <td class="text-center" *ngIf="cuota['Detalle'] == 'Seña pagada'"></td>
                  <td *ngIf="cuota['Pagado']>0">Pagada</td>
                  <td *ngIf="cuota['Pagado']>0 && cuota['Detalle'] != 'Seña pagada'"><button type="button" class="btn btn-info" (click)="verFactura(cuota)"
                      title="Ver Factura">Factura</button></td>
                  <td *ngIf="cuota['Detalle'] == 'Seña pagada'"></td>
                  <td *ngIf="cuota['Pagado']==0">Impaga</td>
                  <td *ngIf="cuota['Pagado']==0 && cuota['Vto_1']>=hoy && !refinanciar"><button type="button" class="btn btn-secondary"
                      (click)="pagarCuota(cuota)" title="Pagar cuota">Pagar</button></td>
                  <td *ngIf="cuota['Vto_1']<hoy && cuota['Pagado']==0  && !refinanciar"><button type="button" class="btn btn-danger"
                      title="Cuota vencida">Vencida</button></td>
                  <td *ngIf="cuota['Pagado']==0  && refinanciar"><button type="button" class="btn btn-warning"
                    title="Comunicarse con Viafin">Bloqueado</button></td>
                </tr>
              </tbody>
            </table>
            <button type="button" class="btn btn-secondary ml-2" (click)="btnCancelarCuotas()">Cancelar</button>
          </div>
        </div>

      </div>
      <div class="container" *ngIf="pagoOk">
        <div class="alert alert-success alert-dismissible fade show" role="alert" *ngIf="pagoOk">
          <strong>Informacion de la transacción:</strong>
          <br>
          {{mensajeError}}
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <br>
          <div class="card">
            <h5 class="card-header">Cuota </h5>
            <div class="card-body">
              <h5 class="card-title">{{laCuota['Detalle']}}</h5>
              <h5 class="card-title">Vencimiento: {{laCuota['Vto_1'] | date:"dd/MM/Y"}}</h5>
              <h5 class="card-title">Importe : {{laCuota['Importe_2'] | currency}}</h5>
            </div>
          </div>
          <button type="button" class="btn btn-secondary m-2" (click)="loginAutogestion()">Continuar</button>
        </div>
      </div>
      <div class="container" *ngIf="pagarStatus">
        <div class="card">
          <div class="card-body">
            <h2>Pasajero: {{pasajero['Apellido_y_Nombre']}}</h2>
            <h3>Contrato: {{elContrato['Descripcion']}}</h3>
            <h3>Viaje: {{elContrato['viaje']['Descripcion']}}</h3>
            <h3>A pagar: {{laCuota['Detalle']}} - Vence:. {{laCuota['Vto_2'] | date: "dd/MM/Y"}} -
              {{laCuota['Importe_2'] | currency}} </h3>
            <br>
            <form [formGroup]="loginForm">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="cardNombre">Titular de la tarjeta</label>
                  <input type="text" class="form-control" formControlName="cardNombre"
                    [ngClass]="isInvalid('cardNombre') ? 'is-invalid' : ''">
                </div>
                <div class="form-group col-md-6">
                  <label for="inputEmail4">Metodo de pago</label>
                  <select id="cardMetodo" class="form-control" formControlName="cardMetodo" name="loginForm[cardMetodo]"
                    [ngClass]="isInvalid('cardMetodo') ? 'is-invalid' : ''">
                    <option *ngFor="let metodo of metodos" value="{{metodo['MedioPagoId']}}">{{metodo['Descripcion']}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="cardNumero">Numero Tarjeta</label>
                  <input type="text" class="form-control" formControlName="cardNumero"
                    [ngClass]="isInvalid('cardNumero') ? 'is-invalid' : ''">
                </div>
                <div class="form-group col-md-2">
                  <label for="inputEmail4">Vto.Año</label>
                  <select id="inputState" class="form-control" formControlName="cardAno" name="loginForm[cardAno]"
                    [ngClass]="isInvalid('cardAno') ? 'is-invalid' : ''">
                    <option *ngFor="let ano of anos" value="{{ano}}">{{ano}}</option>
                  </select>
                </div>
                <div class="form-group col-md-2">
                  <label for="carMes">Vto.Mes</label>
                  <select id="cardMes" class="form-control" formControlName="cardMes" name="loginForm[cardMes]"
                    [ngClass]="isInvalid('cardMes') ? 'is-invalid' : ''">
                    <option *ngFor="let mes of meses" value="{{mes}}">{{mes}}</option>
                  </select>
                </div>
                <div class="form-group col-md-2">
                  <label for="cardCodigo">Código</label>
                  <input type="text" class="form-control" id="cardCodigo" formControlName="cardCodigo"
                    name="loginForm[cardCodigo]" [ngClass]="isInvalid('cardCodigo') ? 'is-invalid' : ''">
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="cardEmail">Email</label>
                  <input type="email" class="form-control" id="cardEmail" formControlName="cardEmail"
                    name="loginForm[cardEmail]" [ngClass]="isInvalid('cardEmail') ? 'is-invalid' : ''">
                </div>
                <div class="form-group col-md-2">
                  <label for="cardDNI">DNI titular</label>
                  <input type="text" class="form-control" id="cardDNI" formControlName="cardDNI"
                    name="loginForm[cardDNI]" [ngClass]="isInvalid('cardDNI') ? 'is-invalid' : ''">
                </div>
                <div class="form-group col-md-2">
                  <label for="cardNacimiento">Fecha nacimiento titular</label>
                  <input type="date" class="form-control" id="cardNacimiento" formControlName="cardNacimiento"
                    name="loginForm[cardNacimiento]" [ngClass]="isInvalid('cardNacimiento') ? 'is-invalid' : ''">
                </div>
                <div class="form-group col-md-2">
                  <label for="cardPuerta">Nro. Puerta resumen</label>
                  <input type="text" class="form-control" id="cardPuerta" formControlName="cardPuerta"
                    name="loginForm[cardPuerta]" [ngClass]="isInvalid('cardPuerta') ? 'is-invalid' : ''">
                </div>
              </div>
              <div class="form-row mb-2" *ngIf="!pagandoLoading">
                <button type="submit" class="btn btn-primary" (click)="btnPagar()">Pagar</button>
                <button type="button" class="btn btn-secondary ml-2" (click)="btnCancelar()">Cancelar</button>
              </div>
              <div class="form-row mt-2" *ngIf="pagandoLoading">
                <div class="d-flex align-items-center">
                  <strong>Aguarde, procesando el pago...</strong>
                  <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                </div>
              </div>
            </form>
            <div class="alert alert-warning alert-dismissible fade show" role="alert" *ngIf="pagoError">
              <strong>Error al procesar el pago:</strong>
              <br>
              {{mensajeError}}
              <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col" *ngIf="nuevo">
      <div class="row justify-content-md-center">
        <div class="card col-10">
          <form [formGroup]="paxForm">
            <div class="form-row mb-2">
              <button class="btn m-2" [ngClass]="paso1 ? 'btn-secondary' : 'btn-outline-secondary'"
                (click)="btnPaso1()">Paso 1</button>
              <button class="btn m-2" [ngClass]="paso2 ? 'btn-secondary' : 'btn-outline-secondary'"
                (click)="btnPaso2()">Paso 2</button>
              <button class="btn m-2" [ngClass]="paso3 ? 'btn-secondary' : 'btn-outline-secondary'"
                (click)="btnPaso3()">Paso 3</button>
            </div>
            <div class="">

              <div class="tab-panels">
                <section id="paso1" class="tab-panel" *ngIf="paso1">
                  <div class="card-title">
                    <h1>Datos Pasajero</h1>
                  </div>
                  <div class="card-body">

                    <div class="form-row">
                      <div class="form-group col-4">
                        <label for="paxNombre">Apellido y Nombres</label>
                        <input type="text" class="form-control" id="paxNombre" formControlName="paxNombre"
                          name="paxForm[paxNombre]" [ngClass]="isInvalidPax('paxNombre') ? 'is-invalid' : ''">
                      </div>
                      <div class="form-group col-4">
                        <label for="paxDomicilio">Domicilio</label>
                        <input type="text" class="form-control" id="paxDomicilio" formControlName="paxDomicilio"
                          name="paxForm[paxDomicilio]" [ngClass]="isInvalidPax('paxDomicilio') ? 'is-invalid' : ''">
                      </div>
                      <div class="form-group col-4">
                        <label for="paxTelefono">Teléfono</label>
                        <input type="text" class="form-control" id="paxTelefono" formControlName="paxTelefono"
                          name="paxForm[paxTelefono]" [ngClass]="isInvalidPax('paxTelefono') ? 'is-invalid' : ''">
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-md-4">
                        <label for="paxNacimiento">F.Nacimiento</label>
                        <input type="date" class="form-control" id="paxNacimiento" formControlName="paxNacimiento"
                          name="paxForm[paxNacimiento]" [ngClass]="isInvalidPax('paxNacimiento') ? 'is-invalid' : ''">
                      </div>
                      <div class="form-group col-4">
                        <label for="paxEmail">Email</label>
                        <input type="email" class="form-control" id="paxEmail" formControlName="paxEmail"
                          name="paxForm[paxEmail]" [ngClass]="isInvalidPax('paxEmail') ? 'is-invalid' : ''">
                      </div>
                      <div class="form-group col-4">
                        <label for="paxSexo">Sexo</label>
                        <select id="paxSexo" class="form-control" formControlName="paxSexo" name="paxForm[paxSexo]"
                          [ngClass]="isInvalidPax('paxSexo') ? 'is-invalid' : ''">
                          <option *ngFor="let sexo of sexos" value="{{sexo['idsexo']}}">{{sexo['Descripcion']}}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-4">
                        <label for="paxLocalidad">Localidad</label>
                        <select id="paxLocalidad" class="form-control" formControlName="paxLocalidad"
                          name="paxForm[paxLocalidad]" [ngClass]="isInvalidPax('paxLocalidad') ? 'is-invalid' : ''">
                          <option *ngFor="let localidad of localidades" value="{{localidad['idlocalidad']}}">
                            {{localidad['Descripcion']}}
                          </option>
                        </select>
                      </div>
                      <div class="form-group col-4">
                        <label for="paxPais">Pais</label>
                        <select id="paxPais" class="form-control" formControlName="paxPais" name="paxForm[paxPais]"
                          [ngClass]="isInvalidPax('paxPais') ? 'is-invalid' : ''">
                          <option *ngFor="let pais of paises" value="{{pais['idpais']}}">{{pais['Descripcion']}}
                          </option>
                        </select>
                      </div>
                      <div class="form-group col-4">
                        <label for="paxAlimenticio">Alimenticio</label>
                        <select id="paxAlimenticio" class="form-control" formControlName="paxAlimenticio"
                          name="paxForm[paxAlimenticio]" [ngClass]="isInvalidPax('paxAlimenticio') ? 'is-invalid' : ''">
                          <option *ngFor="let alimenticio of alimenticios" value="{{alimenticio['idalimenticio']}}">
                            {{alimenticio['Descripcion']}}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-4">
                        <label for="paxDto">Tipo documento</label>
                        <select id="paxDto" class="form-control" formControlName="paxDto" name="paxForm[paxDto]"
                          [ngClass]="isInvalidPax('paxDto') ? 'is-invalid' : ''">
                          <option *ngFor="let tipo of tipos" value="{{tipo['iddto']}}">{{tipo['Descripcion']}}
                          </option>
                        </select>
                      </div>
                      <div class="form-group col-4">
                        <label for="paxDni">Nº de documento</label>
                        <input type="text" class="form-control" id="paxDni" formControlName="paxDni"
                          name="paxForm[paxDni]" disabled>
                      </div>
                      <div class="form-group col-4">
                        <label for="paxContrato">Contrato</label>
                        <input type="text" class="form-control" id="paxContrato" formControlName="paxContrato"
                          name="paxForm[paxContrato]" disabled>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-6">
                        <label for="paxFacebook">Facebook</label>
                        <input type="text" class="form-control" id="paxFacebook" formControlName="paxFacebook"
                          name="paxForm[paxFacebook]">
                      </div>
                      <div class="form-group col-6">
                        <label for="paxInstagram">Instagram</label>
                        <input type="text" class="form-control" id="paxInstagram" formControlName="paxInstagram"
                          name="paxForm[paxInstagram]">
                      </div>
                    </div>
                  </div>
                  <div class="row justify-content-md-center" *ngIf="!nuevoLoading">
                    <button type="button" class="btn btn-outline-danger col-5 m-2"
                      (click)="btnCancelarCuotas()">Cancelar</button>
                    <button type="button" class="btn btn-outline-success col-5 m-2" (click)="btnPaso2()">Paso 2</button>
                  </div>
                </section>
                <section id="paso2" class="tab-panel" *ngIf="paso2">
                  <div class="card-title">
                    <h1>Datos Reponsable</h1>
                  </div>
                  <div class="card-body">

                    <div class="form-row">
                      <div class="form-group col-4">
                        <label for="resNombre">Apellido y Nombres</label>
                        <input type="text" class="form-control" id="resNombre" formControlName="resNombre"
                          name="paxForm[resNombre]" [ngClass]="isInvalidPax('resNombre') ? 'is-invalid' : ''">
                      </div>
                      <div class="form-group col-4">
                        <label for="resDomicilio">Domicilio</label>
                        <input type="text" class="form-control" id="resDomicilio" formControlName="resDomicilio"
                          name="paxForm[resDomicilio]" [ngClass]="isInvalidPax('resDomicilio') ? 'is-invalid' : ''">
                      </div>
                      <div class="form-group col-4">
                        <label for="resTelefono">Teléfono</label>
                        <input type="text" class="form-control" id="resTelefono" formControlName="resTelefono"
                          name="paxForm[resTelefono]" [ngClass]="isInvalidPax('resTelefono') ? 'is-invalid' : ''">
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-md-4">
                        <label for="resNacimiento">F.Nacimiento</label>
                        <input type="date" class="form-control" id="resNacimiento" formControlName="resNacimiento"
                          name="paxForm[resNacimiento]" [ngClass]="isInvalidPax('resNacimiento') ? 'is-invalid' : ''">
                      </div>
                      <div class="form-group col-4">
                        <label for="resEmail">Email</label>
                        <input type="email" class="form-control" id="resEmail" formControlName="resEmail"
                          name="paxForm[resEmail]" [ngClass]="isInvalidPax('resEmail') ? 'is-invalid' : ''">
                      </div>
                      <div class="form-group col-4">
                        <label for="resSexo">Sexo</label>
                        <select id="resSexo" class="form-control" formControlName="resSexo" name="paxForm[resSexo]"
                          [ngClass]="isInvalidPax('resSexo') ? 'is-invalid' : ''">
                          <option *ngFor="let sexo of sexos" value="{{sexo['idsexo']}}">{{sexo['Descripcion']}}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-4">
                        <label for="resLocalidad">Localidad</label>
                        <select id="resLocalidad" class="form-control" formControlName="resLocalidad"
                          name="paxForm[resLocalidad]" [ngClass]="isInvalidPax('resLocalidad') ? 'is-invalid' : ''">
                          <option *ngFor="let localidad of localidades" value="{{localidad['idlocalidad']}}">
                            {{localidad['Descripcion']}}
                          </option>
                        </select>
                      </div>

                      <div class="form-group col-4">
                        <label for="resParentezco">Parentezco</label>
                        <select id="resParentezco" class="form-control" formControlName="resParentezco"
                          name="paxForm[resParentezco]" [ngClass]="isInvalidPax('resParentezco') ? 'is-invalid' : ''">
                          <option *ngFor="let parentezco of parentezcos" value="{{parentezco['idparentezco']}}">
                            {{parentezco['Descripcion']}}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-4">
                        <label for="resDto">Tipo documento</label>
                        <select id="resDto" class="form-control" formControlName="resDto" name="paxForm[resDto]"
                          [ngClass]="isInvalidPax('resDto') ? 'is-invalid' : ''">
                          <option *ngFor="let tipo of tipos" value="{{tipo['iddto']}}">{{tipo['Descripcion']}}
                          </option>
                        </select>
                      </div>
                      <div class="form-group col-4">
                        <label for="resDni">Nº de documento</label>
                        <input type="text" class="form-control" [ngClass]="isInvalidPax('resDni') ? 'is-invalid' : ''"
                          id="resDni" formControlName="resDni" name="paxForm[resDni]">
                      </div>
                    </div>
                  </div>
                  <div class="row justify-content-md-center" *ngIf="!nuevoLoading">
                    <button type="button" class="btn btn-outline-danger col-5 m-2" (click)="btnPaso1()">Paso 1</button>
                    <button type="button" class="btn btn-outline-success col-5 m-2" (click)="btnPaso3()">Paso 3</button>
                  </div>
                </section>
                <section id="paso3" class="tab-panel" *ngIf="paso3">
                  <div class="card-title">
                    <h1>Forma de pago</h1>
                  </div>
                  <div class="card-body">

                    <div class="form-group col-6">
                      <label for="plaPlan">Plan de pago</label>
                      <select id="plaPlan" class="form-control" formControlName="plaPlan" name="paxForm[plaPlan]"
                        [ngClass]="isInvalidPax('plaPlan') ? 'is-invalid' : ''">
                        <option *ngFor="let plan of planes" value="{{plan['idplan']}}">{{plan['Descripcion']}}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-6">
                      <label for="plaPrimera">Primera cuota</label>
                      <select id="plaPrimera" class="form-control" formControlName="plaPrimera"
                        name="paxForm[plaPrimera]" [ngClass]="isInvalidPax('plaPrimera') ? 'is-invalid' : ''">
                        <option *ngFor="let primera of primeras" value="{{primera['idprimercuota']}}">
                          {{primera['Descripcion']}}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-6">
                      <label for="plaObservacion">Observaciones</label>
                      <textarea type="text" class="form-control" id="resTelefono" formControlName="plaObservacion"
                        name="paxForm[plaObservacion]" cols="30" rows="10"></textarea>
                    </div>
                  </div>
                  <div class="row justify-content-md-center" *ngIf="!nuevoLoading">
                    <button type="button" class="btn btn-outline-danger col-5 m-2" (click)="btnPaso2()">Paso 2</button>
                    <button type="button" class="btn btn-outline-success col-5 m-2"
                      (click)="btnNuevo()">Confirmar</button>
                    <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="nuevoCompletar">
                      <strong>¡Atención!:</strong>
                      <br>
                      <h5 class="">Debes completar todos los datos correctamente</h5>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div class="col-12" *ngIf="nuevoLoading">
              <div class="text-center">
                <button type="button" class="btn btn-block btn-outline-success col-5 m-2">
                  <span class="">Aguarde...</span>
                  <div class="spinner-border" role="status"></div>
                </button>
              </div>
            </div>
            <div class="container" *ngIf="errorNuevo">
              <div class="alert alert-danger alert-dismissible fade show" role="alert">
                <strong>Error al grabar los datos</strong>
                <br>
                <h5 class="card-header">{{mensajeErrorNuevo}}</h5>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

  </div>
</div>
